import React from "react";
import HomeSearchBar from "../../components/HomeSearchBar/HomeSearchBar";
import "./Home.css";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import HomeCategories from "../../components/HomeCategories/HomeCategories";
import HomeAuctionList from "../../components/HomeAuctionList/HomeAuctionList";
import {
  getHomeCategoriesAPI,
  getFeaturedAuctionsAPI,
  getDashboardAPI,
} from "../../apis/home/HomeAPIs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  _setAuctionsAction,
  _setCategoriesAction,
} from "../../redux/actions/home/HomeActions";
import { useCallback } from "react";
import { useState } from "react";
import Loader from "../../components/Loader/Loader";
import { Row } from "antd";
import { useNavigate } from "react-router-dom";
import { constRoute } from "../../utils/Constants";
import { useTranslation } from "react-i18next";
import socket from "../../socket";
import moment from "moment";

const Home = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const categories = useSelector((state) => state.home.categories);
  const auctions = useSelector((state) => state.home.auctions);
  const [selectedCategory] = useState("");
  const [searchKeyword] = useState("");
  const [loading, setLoading] = useState(false);

  const viewAllCategories = () => navigate(constRoute.allCategories);

  // const fetchCategories = useCallback(async () => {
  //   try {
  //     setLoading(true);
  //     const result = await getHomeCategoriesAPI({ limit: 8 });
  //     if (result.data?.statusCode === 200) {
  //       setLoading(false);
  //       dispatch(_setCategoriesAction(result.data.data));
  //     }
  //   } catch (e) {
  //     setLoading(false);
  //     console.log("error in fetch categories...", e);
  //   }
  // }, [dispatch]);

  const fetchDashboardData = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getDashboardAPI();
      if (result.data?.statusCode === 200) {
        setLoading(false);
        dispatch(_setCategoriesAction(result.data.data.categories));
        dispatch(_setAuctionsAction(result.data.data.auctions));
      }
    } catch (e) {
      setLoading(false);
      console.log("error in fetch categories...", e);
    }
  }, [dispatch]);

  const fetchAuctions = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getFeaturedAuctionsAPI(
        selectedCategory,
        searchKeyword
      );
      if (result.data?.statusCode === 200 && result.data.data) {
        setLoading(false);
        const data = selectedCategory
          ? result.data.data
          : result.data.data.filter((item) => item.isFeature);
        dispatch(_setAuctionsAction(data));
        // dispatch(_setAllAuctionsAction(data));
      }
    } catch (e) {
      setLoading(false);
      console.log("error in fetch auctions...", e);
    }
  }, [dispatch, selectedCategory, searchKeyword]);

  const handleSocketEvent = useCallback(async () => {
    try {
      await fetchAuctions();
    } catch (error) {
      return;
    }
  }, []);

  useEffect(() => {
    socket.on("auction", (data) => {
      handleSocketEvent(data);
    });
    return () => {
      socket.off("auction", handleSocketEvent);
    };
  }, [handleSocketEvent]);

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  useEffect(() => {
    //fetchAuctions();
  }, [fetchAuctions, selectedCategory]);

  const openAuctionsByCategory = (categoryId, title) => {
    if (categoryId === "allAuctions") {
      navigate(constRoute.auctions, {
        state: { category: { id: "", title } },
      });
    } else {
      navigate(constRoute.auctions, {
        state: { category: { id: categoryId, title } },
      });
    }
  };

  const openAuctionsBySearch = (search) => {
    if (search?.length > 0) {
      navigate(constRoute.auctions, {
        state: { search },
      });
    }
  };

  const handleAuctionClosed = (id) => {
    const _data = [...auctions];
    const updatedAuctionList = _data.filter((auction) => {
      const currentTime = moment().tz("America/Chicago");
      const endsAtTime = moment(auction.endAt);
      const timeDifference = endsAtTime.diff(currentTime, "milliseconds");

      return timeDifference > 0; // Filter items with positive time difference
    });

    // Update the state with the filtered list of auctions
    dispatch(_setAuctionsAction(updatedAuctionList));
  };

  return (
    <div className="common-layout home-main">
      <Loader loading={loading} />
      <ProtectedHeader />
      <section className="header-section">
        <HomeSearchBar
          // onChange={(e) => setSearchKeyword(e.target?.value ?? "")}
          onChange={(e) => openAuctionsBySearch(e.target?.value ?? "")}
          placeholder={t(`home.searchAuctions`)}
        />
        <Row
          align={"middle"}
          justify="space-between"
          style={{ marginTop: 30, marginBottom: 30 }}
        >
          <h2 className="bold__font">{t(`home.exploreCategories`)}</h2>
          {/* <p
            onClick={viewAllCategories}
            className="t-red t-underline medium__font t-cursor"
          >
            {categories?.length > 7 && t(`home.viewAll`)}
          </p> */}
        </Row>
        <HomeCategories
          data={categories}
          selected={selectedCategory}
          // onSelect={selectCategory}
          onSelect={(id, title) => openAuctionsByCategory(id, title)}
        />
      </section>
      <section className="body-section">
        <HomeAuctionList
          data={auctions}
          onAuctionClosed={handleAuctionClosed}
          loading={loading}
        />
      </section>
    </div>
  );
};

export default Home;
