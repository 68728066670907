import { Input } from "antd";
import SearchIcon from "../../assets/search-icon.svg";
import "./HomeSearchBar.css";

const HomeSearchBar = ({ value, onChange, placeholder }) => {
  return (
    <Input
      className={"home-search"}
      prefix={<img src={SearchIcon} height={"60%"} alt={"search"} />}
      placeholder={placeholder}
      onChange={(e) => (e.target.value ? null : onChange(e.target.value))}
      onPressEnter={onChange}
    />
  );
};

export default HomeSearchBar;
