import { useMediaQuery } from "@uidotdev/usehooks";
import { Card, Row } from "antd";
import { useTranslation } from "react-i18next";
import MCLogoRed from "../../assets/mc-circle-logo.png";

const HomeCategories = ({ data, selected, onSelect, type, ...props }) => {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const { t } = useTranslation();

  const staticCategory = {
    id: "allAuctions",
    image: MCLogoRed,
    title: t(`home.allAuctions`),
  };
  return (
    <>
      <div
        className={"category-item all-auctions-category"}
        key={staticCategory.id}
        onClick={() =>
          staticCategory.id !== selected
            ? onSelect(staticCategory.id, staticCategory.title)
            : null
        }
      >
        <Card
          bordered={false}
          hoverable
          className={`category-card ${
            staticCategory.id === selected ? "selected-category" : ""
          }`}
        >
          <img alt={"category"} src={staticCategory.image} />
        </Card>
        <p className="bold__font t-center t-slate">{staticCategory.title}</p>
      </div>
      <Row
        className="categories-main disable-scrollbars"
        style={{ flexWrap: type === "all" ? "wrap" : "nowrap" }}
        justify={isSmallDevice && type === "all" ? "center" : ""}
      >
        {data?.map((el) => (
          <div
            className={"category-item"}
            key={el.id}
            onClick={() =>
              el.id !== selected ? onSelect(el.id, el.title) : null
            }
          >
            <Card
              bordered={false}
              hoverable
              className={`category-card ${
                el.id === selected ? "selected-category" : ""
              }`}
            >
              <img alt={"category"} src={el.image} />
            </Card>
            <p className="bold__font t-center t-slate">{el.title}</p>
          </div>
        ))}
      </Row>
    </>
  );
};
export default HomeCategories;
