import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { Col, Row } from "antd";
import AboutUsLogo from "../../assets/about-us-logo.svg";
import LeftArrow from "../../assets/left-arrow.svg";
import useWindowSize from "../../utils/hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import { useTranslation } from "react-i18next";
import { getPagesApi } from "../../apis/pages";
import { CREDENTIALS } from "../../utils/Constants";

const AboutUs = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [setAboutUsData] = useState(null);

  const getUserProfileData = async () => {
    try {
      const result = await getPagesApi();
      if (result?.data?.statusCode === 200) {
        setAboutUsData(
          (localStorage.getItem("i18nextLng") === "ch" &&
            result?.data?.data?.aboutUs.valueCh) ||
            (localStorage.getItem("i18nextLng") === "sp" &&
              result?.data?.data?.aboutUs.valueSp) ||
            (localStorage.getItem("i18nextLng") === "ar" &&
              result?.data?.data?.aboutUs.valueAr) ||
            result?.data?.data?.aboutUs.valueEn
        );
      } else throw result;
    } catch (e) {
      console.log("errror in fetch", e);
    }
  };
  useEffect(() => {
    if (localStorage.getItem(CREDENTIALS.AUTH_TOKEN)?.length > 1) {
      getUserProfileData();
    }
  }, []);

  return (
    <>
      <div className={style.headerContainer}>
        <ProtectedHeader />
      </div>{" "}
      <div className={style.pageContainer}>
        <div className={style.fluidContainer}>
          <Row justify={width <= 768 ? "center" : "left"}>
            {width <= 768 && (
              <img
                onClick={() => navigate("/")}
                className={style.arrowIcon}
                src={LeftArrow}
                width={20}
                alt=""
              />
            )}
            <h1>{t("aboutUs.aboutUs")}</h1>
          </Row>
          <Row className={style.fluidRow} gutter={80}>
            <Col className={style.leftCol} md={14} sm={24} xs={24}>
              <div className={style.leftContainer}>
                <p>
                  {t("aboutUsPageData")}
                  <a
                    href="http://machkosher.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    MachKosher.com
                  </a>
                  {t("theProminentGlobal")}
                </p>
                <p>
                  <a
                    href="http://machkosher.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    MachKosher.com
                  </a>
                  {t("weRecognizeImportance")}
                </p>
                <p>
                  <a
                    href="http://machkosher.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    MachKosher.com
                  </a>
                  {t("takeGreatPride")}
                </p>
                <p>
                  <a
                    href="http://machkosher.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    MachKosher.com
                  </a>
                  {t("ultimateDestination")}
                </p>
              </div>
            </Col>
            <Col className={style.rightCol} md={10} sm={24} xs={24}>
              <img src={AboutUsLogo} alt="" />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
