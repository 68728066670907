export const CURRENT_PAGE_ROUTE_URL = "currentPage"
export const EMPTY_NULL_DATA = 'N/A';
export const INITIAL_PAGE_NUMBER = 1
export const INITIAL_PAGE_LIMIT = 20

export const statusConditionalColors = {
    bidStautsBgColorLightBlue: "lightblue",
    bidStautsBgColorLightGreen: "lightgreen",
    bidStautsBgLightRed: "lightcoral",
    bidStautsBgLightYellow: "lightyellow",
    bidStautsBgMuddy: "#8B7355",
    auctionBgWinner: "#E1FFE1",
    auctionBgOutbidded: "#ECE1C5",
}

