import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { Row } from "antd";
import LeftArrow from "../../assets/left-arrow.svg";
import useWindowSize from "../../utils/hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import { useTranslation } from "react-i18next";
import { getPagesApi } from "../../apis/pages";
import NoDataFound from "../../components/CommonComponents/no-data-found";

const TermsAndCondition = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const [termsAndConditionsData, setTermsAndConditionsData] = useState(null);

  const getUserProfileData = async () => {
    try {
      const result = await getPagesApi();
      if (result?.data?.statusCode === 200) {
        setTermsAndConditionsData(
          (localStorage.getItem("i18nextLng") === "ch" &&
            result?.data?.data?.termsAndConditions?.valueCh) ||
            (localStorage.getItem("i18nextLng") === "sp" &&
              result?.data?.data?.termsAndConditions?.valueSp) ||
            (localStorage.getItem("i18nextLng") === "ar" &&
              result?.data?.data?.termsAndConditions?.valueAr) ||
            result?.data?.data?.termsAndConditions?.valueEn
        );
      } else throw result;
    } catch (e) {
      console.log("errror in fetch", e);
    }
  };

  useEffect(() => {
      getUserProfileData() 
  }, []);
  
  return (
    <>
      <div className={style.headerContainer}>
        <ProtectedHeader />
      </div>
      <div className={style.pageContainer}>
        <div className={style.fluidContainer}>
          <Row justify={width <= 768 ? "center" : "left"}>
            {width <= 768 && (
              <img
                onClick={() => navigate("/")}
                className={style.arrowIcon}
                src={LeftArrow}
                width={20}
                alt=""
              />
            )}
            <h1>{t(`footer.termsConditions`)}</h1>
          </Row>
          <Row className={style.fluidRow}>
            {(termsAndConditionsData && (
              <div
                className={style.leftContainer}
                dangerouslySetInnerHTML={{ __html: termsAndConditionsData }}
              ></div>
            )) || <NoDataFound />}
          </Row>
        </div>
      </div>
    </>
  );
};

export default TermsAndCondition;
