import { useMediaQuery } from "@uidotdev/usehooks";
import { Col, Row } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategoriesAPI } from "../../apis/home/HomeAPIs";
import HomeCategories from "../../components/HomeCategories/HomeCategories";
import Loader from "../../components/Loader/Loader";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import { _setCategoriesAction } from "../../redux/actions/home/HomeActions";
import backIcon from "../../assets/back-arrow-icon.svg";
import { useNavigate } from "react-router-dom";
import "./AllCategories.css";
import { constRoute } from "../../utils/Constants";
import { useTranslation } from "react-i18next";

const AllCategories = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const categories = useSelector((state) => state.home.categories);
  const [loading, setLoading] = useState(false);

  const fetchAllCategories = useCallback(async () => {
    try {
      setLoading(true);
      const page = 1;
      const limit = 1000;
      const result = await getAllCategoriesAPI(limit, page);
      if (result.data?.statusCode === 200) {
        setLoading(false);
        dispatch(_setCategoriesAction(result.data.data));
      }
    } catch (e) {
      setLoading(false);
      console.log("error in fetch categories...", e);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchAllCategories();
  }, [fetchAllCategories]);

  const goBack = () => navigate(-1);

  const openAuctionsByCategory = (categoryId, title) => {
    // navigate(constRoute.allAuctions, {
    //   state: { category: { id: categoryId, title } },
    // });

    if (categoryId === "allAuctions") {
      navigate(constRoute.auctions, {
        state: { category: { id: "", title } },
      });
    } else {
      navigate(constRoute.auctions, {
        state: { category: { id: categoryId, title } },
      });
    }
  };

  return (
    <div className="common-layout all-categories-main">
      <Loader loading={loading} />
      <ProtectedHeader />
      <section>
        <Row
          align={"middle"}
          justify="space-between"
          style={{ marginTop: 30, marginBottom: 30 }}
        >
          {isSmallDevice && (
            <Col xs={2}>
              <button onClick={goBack} className={"back-button"}>
                <img src={backIcon} alt={"back"} />
              </button>
            </Col>
          )}
          <Col xs={20}>
            <h2 className="bold__font">{t(`home.exploreCategories`)}</h2>
          </Col>
        </Row>
        <HomeCategories
          data={categories}
          selected={undefined}
          onSelect={(id, title) => openAuctionsByCategory(id, title)}
          type={"all"}
        />
      </section>
    </div>
  );
};

export default AllCategories;
