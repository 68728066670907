// import { Header } from "antd/es/layout/layout";
import { Row } from "antd";
import React, { useEffect, useState } from "react";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import WhatsAppIcon from "../../assets/whatsapp-icon.svg";
import TwitterIcon from "../../assets/twitter-icon.svg";
import InstagramIcon from "../../assets/instagram-icon.png";
import FacebookIcon from "../../assets/facebook-icon.svg";
import Link from "antd/es/typography/Link";
import { useTranslation } from "react-i18next";
import { getCompanyAPI } from "../../apis/company/index";
import {
  renderItemDataOrEmptyNull,
  requestErrorHandel,
} from "../../utils/common-functions";
import { _getCompanyData } from "../../redux/actions/company";
import { useDispatch } from "react-redux";

const AppHeader = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [companyData, setCompanyData] = useState(null);

  const getCompany = async () => {
    setLoading(true);
    try {
      const response = await getCompanyAPI();
      if (response?.data?.statusCode === 200) {
        dispatch(_getCompanyData(response?.data?.data));
        setCompanyData(response?.data?.data);
      }
    } catch (error) {
      // requestErrorHandel(error, navigate);
      requestErrorHandel(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompany();
  }, []);

  return (
    <header className="header">
      <Row className="first-row" align={"middle"}>
        <p className="header-text">{t(`header.followUs`)}</p>
        <Link href={companyData?.facebook?.value}>
          <img src={FacebookIcon} alt={"facebook"} />
        </Link>
        <Link href={companyData?.instagram?.value}>
          <img src={InstagramIcon} alt={"instagram"} />
        </Link>
        <Link href={companyData?.twitter?.value}>
          <img src={TwitterIcon} alt={"twitter"} />
        </Link>
        <Link href={`https://wa.me/${companyData?.whatsApp?.value}`}>
          <img src={WhatsAppIcon} alt={"whatsapp"} />
        </Link>
      </Row>
      <p className="header-text">
        {t(`header.email`)}:{" "}
        <a className="header-text" href={`mailto:${companyData?.email?.value}`}>
          {renderItemDataOrEmptyNull(companyData?.email?.value)}
        </a>
      </p>
    </header>
  );
};

export default AppHeader;
