import React from "react";
import style from "./style.module.scss";
import { Col, Row } from "antd";
import LeftArrow from "../../assets/left-arrow.svg";
import useWindowSize from "../../utils/hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import PhoneIcon from "../../assets/phone-call.svg";
import FaxIcon from "../../assets/fax.svg";
import AddressIcon from "../../assets/address.svg";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import { useTranslation } from "react-i18next";
import { renderItemDataOrEmptyNull } from "../../utils/common-functions";
import { useSelector } from "react-redux";
import WeChatIco from "../../assets/wechat-icon.svg";

const ContactUS = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const companyData = useSelector((state) => state.companyData.companyData);

  const handleColumnClick = (value) => {
    window.location.href = value;
  };

  return (
    <>
      <div className={style.headerContainer}>
        <ProtectedHeader />
      </div>
      <div className={style.pageContainer}>
        <div className={style.fluidContainer}>
          <Row justify={width <= 768 ? "center" : "left"}>
            {width <= 768 && (
              <img
                onClick={() => navigate("/")}
                className={style.arrowIcon}
                src={LeftArrow}
                width={20}
                alt=""
              />
            )}
            <h1>{t(`contactUs.contactUs`)}</h1>
          </Row>

          <Row className={style.fluidRow} gutter={40}>
            {/* <Col md={12} sm={24} xs={24}>
              <Row className={style.contactCardContainer}>
                <img src={PhoneIcon} alt="" />
                <div>
                  <p>{t(`contactUs.telephone`)}</p>
                  <p>
                    <a href={`tel:${companyData?.phoneNo?.value}`}>
                      {renderItemDataOrEmptyNull(companyData?.phoneNo?.value)}
                    </a>
                  </p>
                </div>
              </Row>
            </Col> */}

            <Col
              md={12}
              sm={24}
              xs={24}
              onClick={() =>
                handleColumnClick(`tel:${companyData?.phoneNo?.value}`)
              }
              style={{ cursor: "pointer" }}
            >
              <Row className={style.contactCardContainer}>
                <img src={PhoneIcon} alt="" />
                <div>
                  <p>{t(`contactUs.telephone`)}</p>
                  <p>
                    {renderItemDataOrEmptyNull(companyData?.phoneNo?.value)}
                  </p>
                </div>
              </Row>
            </Col>

            <Col
              md={12}
              sm={24}
              xs={24}
              onClick={() =>
                handleColumnClick(`mailto:${companyData?.email?.value}`)
              }
              style={{ cursor: "pointer" }}
            >
              <Row className={style.contactCardContainer}>
                <img src={FaxIcon} alt="" />
                <div>
                  <p>{t(`contactUs.email`)}</p>{" "}
                  <p>{renderItemDataOrEmptyNull(companyData?.email?.value)}</p>
                </div>
              </Row>
            </Col>
            <Col
              md={12}
              sm={24}
              xs={24}
              // onClick={() => handleColumnClick(companyData?.weChat?.value)}
              style={{ cursor: "pointer" }}
            >
              <Row className={style.contactCardContainer}>
                <img src={WeChatIco} alt="" />
                <div>
                  <p>{t(`weChat`)}</p>
                  <p>{renderItemDataOrEmptyNull(companyData?.weChat?.value)}</p>
                </div>
              </Row>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Row className={style.contactCardContainer}>
                <img src={AddressIcon} alt="" />
                <div>
                  <p>{t(`contactUs.address`)}</p>
                  <p>
                    {t(
                      `${renderItemDataOrEmptyNull(
                        companyData?.address?.value
                      )}`
                    )}
                  </p>
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ContactUS;
