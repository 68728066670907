import React, { useEffect } from "react";
import AppButton from "../../components/Button/Button";
import MachkosherLogo from "../../assets/machkosher-screen-logo.svg";
import { Form, message } from "antd";
import CommonTextInput from "../../components/Input/Input";
import { useState } from "react";
import {
  forgotPasswordVerifyAPI,
  onResendOTPRequest,
  verifyOTPAPI,
} from "../../apis/auth/AuthAPIs";
import { useLocation, useNavigate } from "react-router-dom";
import { constRoute, CREDENTIALS } from "../../utils/Constants";
import { useDispatch } from "react-redux";
import { _setLoginAction } from "../../redux/actions/auth/AuthActions";
import { useTranslation } from "react-i18next";
import { handleError, requestErrorHandel } from "../../utils/common-functions";
// comment
const VerifyOTP = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const phoneNo =
    props.type === "forgot-password" ? props.phoneNo : location.state?.phoneNo;
  const phoneToken = location.state?.phoneToken;
  const [messageApi, contextHolder] = message.useMessage();
  const [counter, setCounter] = useState(59);

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const verifyOTPSignup = async (values) => {
    try {
      setLoading(true);
      const result = await verifyOTPAPI({
        phoneNo: "+" + phoneNo,
        phoneToken,
        otp: Number(values.otp),
      });
      setLoading(false);
      if (result.data.statusCode === 200 && result.data.data) {
        localStorage.setItem(
          CREDENTIALS.AUTH_TOKEN,
          JSON.stringify(result.data.data.authToken)
        );
        dispatch(_setLoginAction(true, result.data.data));
        navigate("/");
      } else throw new Error(result.data.errors.phoneNo);
    } catch (e) {
      console.log("error >>>>", e);
      setLoading(false);
      messageApi.error(
        e.response?.data?.errors?.otp ??
          "An unknown error occured, please try again with the correct otp"
      );
    }
  };

  const verifyOTPForgotPassword = async (values) => {
    try {
      setLoading(true);
      const result = await forgotPasswordVerifyAPI({
        otp: Number(values.otp),
        phoneNo: phoneNo,
      });
      setLoading(false);
      if (result.data.statusCode === 200 && result.data.data) {
        navigate(constRoute.forgotPassword, {
          state: {
            phoneNo: phoneNo,
            phoneToken: result.data.data.token,
          },
        });
      } else throw new Error(result.data.errors.phoneNo);
    } catch (e) {
      console.log("error >>>>", e);
      setLoading(false);
      // handleError(e, messageApi);
      requestErrorHandel(e, navigate);
    }
  };

  const resendOtpForRegistration = async () => {
    try {
      setCounter(59);
      const data = {
        phoneNo: `+${phoneNo}`,
        action: "register",
      };
      const response = await onResendOTPRequest({ data: data });
      if (response.status === 200) {
        messageApi.success("OTP resend successfully");
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const resendOtpForForgotPassord = async () => {
    try {
      setCounter(59);
      const data = {
        phoneNo: `+${phoneNo}`,
        action: "forgotPassword",
      };
      const response = await onResendOTPRequest({ data: data });
      if (response.status === 200) {
        messageApi.success("OTP resend successfully");
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div className="login-main">
      {contextHolder}
      <img src={MachkosherLogo} alt={"logo"} />
      <div className="welcome-text">
        <h3 className="bold__font">{t(`forgotPassword.OTPVerification`)}</h3>
        <p>{t(`forgotPassword.enterCode`)}</p>
      </div>
      <Form
        form={form}
        onFinish={
          props.type === "forgot-password"
            ? verifyOTPForgotPassword
            : verifyOTPSignup
        }
        className={"login-form"}
      >
        <Form.Item
          name={"otp"}
          rules={[
            {
              required: true,
              message: t(`validations.requiredOTP`),
            },
          ]}
        >
          <CommonTextInput
            placeholder={t(`forgotPassword.OTP`)}
            className="input"
          />
        </Form.Item>

        {counter == 0 ? (
          <b
            style={{ color: "red", cursor: "pointer" }}
            onClick={
              props.type === "forgot-password"
                ? resendOtpForForgotPassord
                : resendOtpForRegistration
            }
          >
            Resend OTP
          </b>
        ) : (
          <div className="phone-verify_timer">
            <span>Expires in 00:{counter}</span>
          </div>
        )}
        <br />
        <AppButton
          type="primary"
          className={"sign-in-btn"}
          htmlType={"submit"}
          loading={loading}
        >
          {t(`forgotPassword.submit`)}
        </AppButton>
      </Form>
    </div>
  );
};

export default VerifyOTP;
