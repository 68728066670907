import React from "react";
import style from "./style.module.scss";
import NotFoundIcon from "../../assets/not-found-icon.svg";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={style.headerContainer}>
        <ProtectedHeader />
      </div>
      <div className={style.pageContainer}>
        <div className={style.fluidContainer}>
          <img src={NotFoundIcon} alt="icons" />
          <h1>
            404 <span>{t(`error`)}</span>{" "}
          </h1>
          <p>{t(`pageNotFound`)}</p>
        </div>
      </div>
    </>
  );
};

export default NotFound;
