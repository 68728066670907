import client from "../config";

export const postdepositApi = async (data) => {
  return await client.post(`/application/deposits`, data);
};

export const getWalletApi = async () => {
  return await client.get(`/application/wallet`);
};

export const getDepositRequestApi = async () => {
  return await client.get(`/application/deposits`);
};


