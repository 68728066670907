import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en/translation.json";
import translationAR from "./locales/ar/translation.json";
import translationRUS from "./locales/sp/translation.json";
import translationCH from "./locales/ch/translation.json";
import { LANGUAGE } from "../utils/Constants";


// const fallbackLng = ["en"];
// const availableLanguages = ["en", "ar"];

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    lng: localStorage.getItem(LANGUAGE) || "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    returnObjects: true,
    keySeparator: ".",
    resources: {
      en: {
        translation: translationEN,
      },
      ar: {
        translation: translationAR,
      },
      sp: {
        translation: translationRUS
      },
      ch: {
        translation: translationCH
      },
    },
    react: {
      wait: true
    }
  });
export default i18n;
