import client from "../config"

export const getAllAuctionsAPI = async (grade, ending, categoryId = "", search = "", manufacturer = "", model = "", type = "", pageNumber = 1, limit = 1000) => {
    return await client.get(`/auctions?page=${pageNumber}&limit=${limit}&gradeId=${grade}&type=${type}&isSoon=${ending}&categoryId=${categoryId}&search=${search}&manufactureId=${manufacturer}&modelId=${model}`)
}

export const getAllAuctionsByCategoryAPI = async (categoryId) => {
    return await client.get(`/auctions?page=1&isSoon=&filterType=&categoryId=${categoryId}&limit=1000`)
}

export const getSingleAuctionsAPI = async (id) => {
    return await client.get(`/auctions/${id}`)
}