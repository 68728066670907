import { DatePicker, Input } from "antd";
import React from "react";
import "./Input.css";
import EyeClosedIcon from "../../assets/close-eye-icon.svg";
import EyeOpenedIcon from "../../assets/open-eye-icon.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import ar from "react-phone-input-2/lang/ar.json";
import { useMediaQuery } from "@uidotdev/usehooks";
import localeCh from "antd/es/date-picker/locale/zh_CN"; // Import the Chinese locale
import localeAr from "antd/es/date-picker/locale/ar_EG"; // Import the Arabic locale
import ru_RU from "antd/es/date-picker/locale/ru_RU";
import localeEn from "antd/es/date-picker/locale/en_US"; // Import the Chinese locale
import { defaultValueDate } from "../../utils/common-functions";
import moment from "moment";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const CommonTextInput = ({ isDisabledCode = false, type, ...props }) => {
  const tz = "America/Chicago"; // Dallas time zone
  const defaultDallasDateTime = dayjs().tz(tz);

  const { i18n } = useTranslation();
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const placeholder = `${props.placeholder} ${props.required ? "*" : ""}`;

  const format = "YYYY-MM-DD HH:mm:ss";

  return type === "password" ? (
    <Input.Password
      iconRender={(visible) =>
        visible ? (
          <img src={EyeClosedIcon} alt={"hide password"} />
        ) : (
          <img src={EyeOpenedIcon} alt={"show password"} />
        )
      }
      {...props}
      placeholder={placeholder}
      className={`common-input ${props.className}`}
    />
  ) : type === "phone" ? (
    <div className={i18n.language === "ar" ? "phone-input-ar" : ""}>
      <PhoneInput
        localization={i18n.language === "ar" ? ar : undefined}
        inputStyle={{
          borderRadius: 10,
          height: "2.5rem",
          width: props.width ? props.width : isSmallDevice ? "80vw" : "25vw",
        }}
        buttonStyle={
          i18n.language === "ar"
            ? {
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
                paddingRight: 10,
              }
            : { borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }
        }
        defaultMask={".........."}
        alwaysDefaultMask={".........."}
        specialLabel="Phone Number"
        country={"ae"}
        onChange={(value) => props.setValue(value)}
        value={props.value}
        countryCodeEditable={false}
        enableSearch
        // isValid={(value, country) => {
        //   console.log(value);
        //   const countryCode = country.dialCode;
        //   const onlyNumber = value.split(countryCode)[1];
        //   console.log({ onlyNumber });
        //   if (onlyNumber.length > 0) {
        //     const regex = /^[0-9]{10,15}$/;
        //     // const regex =
        //     // /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{5}[-\s\.]?[0-9]{2,6}$/;
        //     return regex.test(value)
        //       ? true
        //       : "Please enter a valid phone number";
        //   } else return true;
        // }}
        disabled={props.disabled}
      />
    </div>
  ) : type === "number" ? (
    <Input
      {...props}
      className={`common-input ${props.className}`}
      placeholder={placeholder}
      type={type}
      autoComplete={"newpassword"}
      initialValue={props.initialValue}
    />
  ) : type === "date" ? (
    <DatePicker
      {...props}
      className={`common-input date-picker ${props.className}`}
      placeholder={placeholder}
      defaultValue={defaultValueDate}
      type={type}
      locale={
        (localStorage?.getItem("currentLanguage") === "en" && localeEn) ||
        (localStorage?.getItem("currentLanguage") === "ch" && localeCh) ||
        (localStorage?.getItem("currentLanguage") === "ar" && localeAr) ||
        (localStorage?.getItem("currentLanguage") === "sp" && ru_RU)
      }
    />
  ) : type === "simple-date" ? (
    <DatePicker
      className={`common-input date-picker ${props.className}`}
      placeholder={placeholder}
      disabledDate={(current) => current.isAfter(moment())}
      defaultValue={defaultDallasDateTime}
      format={format}
      showTime={true}
      onChange={(e) => {
        props.onChange(e);
      }}
      showSecond={false}
      // locale={
      //   (localStorage?.getItem("currentLanguage") === "en" && localeEn) ||
      //   (localStorage?.getItem("currentLanguage") === "ch" && localeCh) ||
      //   (localStorage?.getItem("currentLanguage") === "ar" && localeAr) ||
      //   (localStorage?.getItem("currentLanguage") === "sp" && ru_RU)
      // }
    />
  ) : (
    <Input
      {...props}
      className={`common-input ${props.className}`}
      placeholder={placeholder}
      autoComplete={"off"}
    ></Input>
  );
};

export default CommonTextInput;
