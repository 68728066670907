import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { Col, Row, message } from "antd";
import LeftArrow from "../../assets/left-arrow.svg";
import useWindowSize from "../../utils/hooks/useWindowSize";
import { useLocation, useNavigate } from "react-router-dom";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import CustomButton from "../../components/CommonComponents/custom-button/CustomButton";
import { payToBidAPI } from "../../apis/bids/BidAPI";
import {
  formatDate,
  getTimeAMPMFromDate,
  renderAuctionItemBGColorOnStatus,
  renderItemDataOrEmptyNull,
  requestErrorHandel,
  successMessage,
} from "../../utils/common-functions";
import { getAddressesAPI } from "../../apis/Addresses";
import Loader from "../../components/Loader/Loader";
import { getCompanyAPI } from "../../apis/company";
import { constRoute } from "../../utils/Constants";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import EyeIcon from "../../assets/open-eye-icon.svg";
import RemoveWatchListIcon from "../../assets/remove-watchlist-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { addToWatchListAPI } from "../../apis/watchlist/WatchlistAPI";
import {
  _addToWatchListAction,
  _removeFromWatchListAction,
} from "../../redux/actions/watchlist/WatchListActions";
import HighestBidderIcon from "../../assets/highest-bidder-icon.svg";

const Checkout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const { width } = useWindowSize();
  const location = useLocation();
  const { t } = useTranslation();
  const myBidsData = location?.state;
  const watchlist = useSelector((state) => state.watchlist.data);
  const [loading, setLoading] = useState(false);
  const [address, setAddresses] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [companyData, setCompanyData] = useState(null);
  const [addressId, setAddressId] = useState(null);

  useEffect(() => {
    getAllAddress();
    getCompany();
  }, []);

  useEffect(() => {
    if (companyData) {
      setGrandTotal(
        myBidsData?.bidStatuses?.myBidPrice +
          myBidsData?.deliveryFee +
          (Number(companyData?.serviceCharges?.value) / 100) *
            myBidsData?.bidStatuses?.myBidPrice
      );
    }
  }, [companyData, myBidsData]);

  const getAllAddress = async () => {
    setLoading(true);
    try {
      const response = await getAddressesAPI();
      if (response?.data?.statusCode === 200) {
        setAddresses(response?.data?.data);
        setAddressId(response?.data?.data[0]?.id);
      }
    } catch (error) {
      requestErrorHandel(error, navigate);
    } finally {
      setLoading(false);
    }
  };

  const getCompany = async () => {
    setLoading(true);
    try {
      const response = await getCompanyAPI();
      if (response?.data?.statusCode === 200) {
        setCompanyData(response?.data?.data);
      }
    } catch (error) {
      requestErrorHandel(error, navigate);
    } finally {
      setLoading(false);
    }
  };

  const onPayToProceed = async () => {
    try {
      const payload = {
        bidId: myBidsData?.winnerBidId,
        amount: grandTotal,
        addressId: addressId,
      };
      const result = await payToBidAPI(payload);
      if (result.data.data && result.data.statusCode === 200) {
        successMessage(t("paymentSuccess"));
        navigate(constRoute?.myBids);
      } else throw result.data;
    } catch (e) {
      requestErrorHandel(e, navigate);
    }
  };

  const isAddedToWatchList = (auctionId) => {
    const watchlistIds = watchlist.map((el) => auctionId);
    return watchlistIds?.includes(auctionId);
  };

  const addToWatchList = async (auctionId) => {
    try {
      setLoading(true);
      const result = await addToWatchListAPI(auctionId);
      setLoading(false);
      if (result.data.statusCode === 200) {
        if (isAddedToWatchList(auctionId)) {
          dispatch(_removeFromWatchListAction(result.data.data));
          messageApi.success("Auction has been removed from your watchlist");
        } else {
          dispatch(_addToWatchListAction(result.data.data));
          messageApi.success("Auction has been added to your watchlist");
        }
      }
    } catch (e) {
      setLoading(false);
      requestErrorHandel(e, navigate);
    }
  };

  const renderBidDetails = (el) => {
    return (
      <Row style={{ margin: "28px", position: "relative" }}>
        <Col md={16} sm={24} xs={24}>
          <Row className="firstRowContainer">
            <Col span={12}>
              <Row className={"auctionPropertiesRow"}>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="medium__font t-slate">
                    {t(`auctionDetails.serialNumber`)}
                  </span>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="semiBold__font">
                    {renderItemDataOrEmptyNull(el?.serialNumber)}
                  </span>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Row className={"auctionPropertiesRow"}>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="textRight medium__font t-slate">
                    {t(`manufacturer`)}
                  </span>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="semiBold__font">
                    {renderItemDataOrEmptyNull(el?.manufacture?.name)}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row className={" auctionPropertiesRow"}>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="medium__font t-slate">
                    {t(`auctionDetails.Sku`)}
                  </span>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span
                    className="semiBold__font"
                    style={{ wordBreak: "break-word" }}
                  >
                    {renderItemDataOrEmptyNull(el?.sku)}
                  </span>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Row className={"auctionPropertiesRow"}>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="textRight medium__font t-slate">
                    {t(`auctionDetails.grade`)}
                  </span>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="semiBold__font">
                    {renderItemDataOrEmptyNull(el?.grade)}
                  </span>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Row className={"auctionPropertiesRow"}>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="medium__font t-slate">{t(`quantity`)}</span>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="semiBold__font">
                    {renderItemDataOrEmptyNull(el?.totalUnits)}
                  </span>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Row className={"auctionPropertiesRow"}>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="textRight medium__font t-slate">
                    {t(`auctionDetails.noOfBids`)}
                  </span>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="semiBold__font">
                    ${renderItemDataOrEmptyNull(el?.totalBids)}
                    /Per Unit
                  </span>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Row className={"auctionPropertiesRow"}>
                {el?.totalBids > 0 ? (
                  <>
                    <Col lg={12} md={24} sm={24} xs={24}>
                      <span className="medium__font t-slate">
                        {t(`auctionDetails.lastBid`)}
                      </span>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                      <span className="semiBold__font">
                        $
                        {renderItemDataOrEmptyNull(
                          el?.lastBidPrice / el?.totalUnits,
                          2
                        )}
                        /Per Unit
                      </span>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col lg={12} md={24} sm={24} xs={24}>
                      <span className="medium__font t-slate">
                        {t(`unitPrice`)}
                      </span>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                      <span className="semiBold__font">
                        $
                        {renderItemDataOrEmptyNull(
                          el?.price / el?.totalUnits,
                          2
                        )}
                        /Per Unit
                      </span>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col className={"bidPriceCol"} md={8} sm={24} xs={24}></Col>
        {/* <Col span={24}>
          <Row className={style.remainingTimeRow}>
            <img
              src={ClockIcon}
              alt={"time"}
              className={style.auctionClockIconCheckout}
            />
            <p className="semiBold__font">
              <TimeRemainingTimer endAt={el.endAt} />
            </p>
          </Row>
        </Col> */}
        <br />
        <br />
        <p className="medium__font" style={{ marginTop: 10 }}>
          Pay Before: {formatDate(el?.bidStatuses?.payBefore)} |{" "}
          {getTimeAMPMFromDate(el?.bidStatuses?.payBefore)}
        </p>
        <div
          className={style.favIconContainer}
          onClick={(e) => {
            e.stopPropagation();
            addToWatchList(el?.id);
          }}
        >
          <img
            src={isAddedToWatchList(el?.id) ? RemoveWatchListIcon : EyeIcon}
            alt={"view"}
          />
        </div>
      </Row>
    );
  };

  const openDetails = (data) => {
    if (data?.bidStatuses?.identifier === "B-0001") {
      navigate(`/auction-detail/${data.id}`);
    }
  };

  return (
    <>
      <div className={style.headerContainer}>
        <ProtectedHeader />
      </div>
      <Loader loading={loading} />
      {contextHolder}
      <div className={style.pageContainer}>
        <div className={style.fluidContainer}>
          <Row justify={width <= 768 ? "center" : "left"}>
            {width <= 768 && (
              <img
                onClick={() => navigate("/")}
                className={style.arrowIcon}
                src={LeftArrow}
                width={20}
                alt=""
              />
            )}
            <h1>Checkout</h1>
          </Row>
          <Row gutter={10} className={style.fluidRow}>
            <Col md={16} sm={24} xs={24}>
              <Row className={style.leftRow}>
                <div
                  onClick={(e) => {
                    openDetails(myBidsData);
                  }}
                  style={{
                    background: renderAuctionItemBGColorOnStatus(
                      myBidsData?.bidStatuses?.identifier
                    ),
                  }}
                  className={classNames(
                    style.auctionItemBoxContainer,
                    myBidsData?.bidStatuses?.identifier === "B-0002"
                      ? style.hoverAuctions
                      : ""
                  )}
                >
                  {myBidsData?.bidStatuses?.barStatus ? (
                    <div
                      className="highestBidder"
                      style={{
                        background: renderAuctionItemBGColorOnStatus(
                          myBidsData?.bidStatuses?.identifier
                        ),
                      }}
                    >
                      <img src={HighestBidderIcon} alt="Highest" />
                      <p>{myBidsData?.bidStatuses?.barStatus}</p>
                    </div>
                  ) : null}
                  {renderBidDetails(myBidsData)}
                </div>

                {(address?.length > 0 &&
                  address?.map((item, index) => {
                    if (index == localStorage.getItem("selectedAddress")) {
                      return (
                        <Row
                          onClick={() => {
                            setAddressId(item?.id);
                          }}
                          className={style.addressRow}
                        >
                          <h6>{t("address")}</h6>
                          <p>
                            {renderItemDataOrEmptyNull(
                              `${item?.title}, ${item?.addressLine1}, ${item?.addressLine2}, ${item?.city}, ${item?.state}, ${item?.country?.name}`
                            )}
                          </p>
                          <Row justify="end">
                            <CustomButton
                              title={t("change")}
                              onClick={() =>
                                navigate(constRoute?.myDeliveryAddress, {
                                  state: myBidsData,
                                })
                              }
                            />
                          </Row>
                        </Row>
                      );
                    } else {
                      if (
                        index === 0 &&
                        localStorage.getItem("selectedAddress") === null
                      ) {
                        return (
                          <Row
                            onClick={() =>
                              navigate(constRoute?.myDeliveryAddress, {
                                state: myBidsData,
                              })
                            }
                            className={style.addressRow}
                          >
                            <h6>{t("address")}</h6>
                            <p>{t("addSelectAddress")}</p>
                          </Row>
                        );
                      }
                    }
                    return null;
                  })) || (
                  <Row
                    onClick={() =>
                      navigate(constRoute?.myDeliveryAddress, {
                        state: myBidsData,
                      })
                    }
                    className={style.addressRow}
                  >
                    <h6>{t("address")}</h6>
                    <p>{t("addSelectAddress")}</p>
                  </Row>
                )}
              </Row>
            </Col>
            <Col md={8} sm={24} xs={24}>
              <Row className={style.rightRow}>
                <h6>{t("payment")}</h6>
                <Row justify={"space-between"}>
                  <p>{t("biddingAmount")}</p>
                  <p>
                    $
                    {renderItemDataOrEmptyNull(
                      myBidsData?.bidStatuses?.myBidPrice,
                      2
                    )}
                  </p>
                </Row>
                <Row justify={"space-between"}>
                  <p>{t("serviceCharges")}</p>
                  <p>
                    $
                    {renderItemDataOrEmptyNull(
                      (Number(companyData?.serviceCharges?.value) / 100) *
                        myBidsData?.bidStatuses?.myBidPrice,
                      2
                    )}
                  </p>
                </Row>
                <Row justify={"space-between"}>
                  <p>{t("deliveryFees")}</p>
                  <p>${renderItemDataOrEmptyNull(myBidsData?.deliveryFee)}</p>
                </Row>
                <Row justify={"space-between"}>
                  <h4>{t("grandTotal")}</h4>
                  <h4>${renderItemDataOrEmptyNull(grandTotal, 2)}</h4>
                </Row>
                <Row justify={"center"}>
                  <CustomButton
                    onClick={() => {
                      onPayToProceed();
                    }}
                    disabled={
                      localStorage.getItem("selectedAddress") === null ||
                      localStorage.getItem("selectedAddress") === "null" ||
                      !address?.length
                    }
                    title={t("proceedToPay")}
                  />
                </Row>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Checkout;
