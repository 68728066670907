import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { Col, Form, Row } from "antd";
import LeftArrow from "../../assets/left-arrow.svg";
import useWindowSize from "../../utils/hooks/useWindowSize";
import { useLocation, useNavigate } from "react-router-dom";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import CustomButton from "../../components/CommonComponents/custom-button/CustomButton";
import CommonTextInput from "../../components/Input/Input";
import CommonDropdown from "../../components/Dropdown/Dropdown";
import { fetchCountriesAPI } from "../../apis/static/StaticAPIs";
import {
  getSingleAddressApi,
  postAddressAPI,
  updateAddressApi,
} from "../../apis/Addresses";
import {
  requestErrorHandel,
  successMessage,
} from "../../utils/common-functions";
import { constRoute } from "../../utils/Constants";
import Loader from "../../components/Loader/Loader";
import { useTranslation } from "react-i18next";

const AddNewAddress = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state.id;
  const myBidsData = location?.state?.myBidsData;
  const { width } = useWindowSize();
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [singleAddressData, setSingleAddressData] = useState(null);

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (id) {
      getSingleAddress();
    }
  }, [id]);

  useEffect(() => {
    if (singleAddressData) {
      form.setFieldsValue({
        title: singleAddressData?.title,
        countryId: singleAddressData?.country?.id,
        state: singleAddressData?.state,
        city: singleAddressData?.city,
        poBox: singleAddressData?.pob,
        addressOne: singleAddressData?.addressLine1,
        addressTwo: singleAddressData?.addressLine2,
      });
    }
  }, [singleAddressData]);

  const fetchCountries = async () => {
    try {
      setLoading(false);
      const result = await fetchCountriesAPI();
      if (result.data.statusCode === 200) {
        let countryList = result.data.data;
        countryList = countryList.map((country) => {
          return {
            value: country.id,
            label: country.name,
          };
        });
        setCountries(countryList);
      } else throw result;
    } catch (e) {
      console.log("errror in fetch country", e);
      setCountries([]);
    } finally {
      setLoading(false);
    }
  };

  const getSingleAddress = async () => {
    try {
      setLoading(false);
      const result = await getSingleAddressApi(id);
      if (result.data.statusCode === 200) {
        setSingleAddressData(result.data.data);
      } else throw result;
    } catch (e) {
      requestErrorHandel(e, navigate);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (values) => {
    const payload = {
      title: values?.title,
      countryId: values?.countryId,
      city: values?.city,
      state: values?.state,
      pob: values?.poBox,
      addressLine1: values?.addressOne,
      addressLine2: values?.addressTwo,
    };
    if (id) {
      try {
        const result = await updateAddressApi({ id, ...payload });
        if (result.data.statusCode === 200) {
          successMessage(t("addressHasBeenUpdated"));
          navigate(constRoute?.myDeliveryAddress, { state: myBidsData });
        } else throw result;
      } catch (e) {
        requestErrorHandel(e, navigate);
      }
    } else {
      try {
        const result = await postAddressAPI(payload);
        if (result.data.statusCode === 200) {
          successMessage(t("addressHasBeenSaved"));
          navigate(constRoute?.myDeliveryAddress, { state: myBidsData });
        } else throw result;
      } catch (e) {
        requestErrorHandel(e, navigate);
      }
    }
  };

  return (
    <div>
      <div className={style.headerContainer}>
        <ProtectedHeader />
      </div>
      <Loader loading={loading} />
      <div className={style.updatePasswordContainer}>
        <div className={style.pageContainer}>
          <div className={style.fluidContainer}>
            <Form
              layout="vertical"
              className={style.cancelModalForm}
              form={form}
              onFinish={onSubmit}
            >
              <Row justify={width <= 768 ? "center" : "left"}>
                {width <= 768 && (
                  <img
                    onClick={() => navigate("/")}
                    className={style.arrowIcon}
                    src={LeftArrow}
                    width={20}
                    alt=""
                  />
                )}
                <h1> {id ? t("updateAddress") : t("addNewAddressCap")} </h1>
              </Row>

              <Row className={style.fluidRow} gutter={40}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item name={"title"}>
                    <CommonTextInput placeholder={t("title")} />
                  </Form.Item>
                </Col>

                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    name={"countryId"}
                    rules={[
                      {
                        required: true,
                        message: t("plzSelectCountry"),
                      },
                    ]}
                  >
                    <CommonDropdown
                      data={countries}
                      placeholder={t("selectCountry")}
                      required
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label?.toLowerCase() ?? "").includes(
                          input.toLowerCase()
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item name={"state"}>
                    <CommonTextInput placeholder={t("state")} />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item name={"city"}>
                    <CommonTextInput placeholder={t("city")} />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item name={"poBox"}>
                    <CommonTextInput placeholder={t("poBox")} />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item name={"addressOne"}>
                    <CommonTextInput placeholder={t("addressLine1")} />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item name={"addressTwo"}>
                    <CommonTextInput placeholder={t("addressLine2")} />
                  </Form.Item>
                </Col>
                <Col className={style.btnContainer} span={24}>
                  <CustomButton
                    htmlType="submit"
                    className={style.btn}
                    title={id ? t("update") : t("submit")}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewAddress;
