import client from "../config";

export const placeBidAPI = async (auctionId, bidPrice, isProxy, bidLimit) => {
  return await client.post(`/application/bids`, {
    auctionId,
    bidPrice: bidPrice,
    isProxy,
    bidLimit: isProxy ? bidLimit : null,
  });
};


export const getMyBidsAPI = async (
  grade,
  ending,
  manufacturer = "",
  model = "",
  type = "",
  status = ""
) => {
  console.log(status, "status");
  return await client.get(
    `/application/bids?page=1&gradeId=${grade}&type=${type}&isSoon=${ending}&manufactureId=${manufacturer}&modelId=${model}&status=${status}&limit=1000`
  );
};

export const payToBidAPI = async (data) => {
  return await client.post(`/application/bid/pay`, data);
};