import { Row } from "antd";
import React from "react";
import style from "./style.module.scss";
import { useTranslation } from "react-i18next";

const NoDataFound = ({
  height = "100%",
  content = (data) => {
    return <p className={style.noDataFound}> {data} </p>;
  },
}) => {
  const { t } = useTranslation();

  return (
    <Row
      className={style.noDataFoundContainer}
      justify={"center"}
      align="middle"
      style={{ height }}
    >
      {content(t("noDataFound"))}
    </Row>
  );
};

export default NoDataFound;
