import { useCallback, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllAuctionsByCategoryAPI } from "../../apis/auctions/AuctionsAPI";
import HomeAuctionList from "../../components/HomeAuctionList/HomeAuctionList";
import Loader from "../../components/Loader/Loader";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import { _setAuctionsAction } from "../../redux/actions/home/HomeActions";
import socket from "../../socket";
import { handleError, requestErrorHandel } from "../../utils/common-functions";

const AllAuctions = (props) => {
  const location = useLocation();
  console.log(location.state);
  const category = location.state?.category;
  const allAuctions = useSelector((state) => state.home.auctions);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchAuctions = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getAllAuctionsByCategoryAPI(category.id);
      if (result.data?.statusCode === 200 && result.data.data) {
        setLoading(false);
        dispatch(_setAuctionsAction(result.data.data));
      }
    } catch (e) {
      setLoading(false);
      console.log("error in fetch auctions...", e);
      requestErrorHandel(e, navigate);
    }
  }, [dispatch, category.id]);

  const handleSocketEvent = useCallback(async () => {
    try {
      await fetchAuctions();
    } catch (error) {
      return;
    }
  }, []);

  useEffect(() => {
    socket.on("auction", (data) => {
      handleSocketEvent(data);
    });
    return () => {
      socket.off("auction", handleSocketEvent);
    };
  }, [handleSocketEvent]);

  useEffect(() => {
    fetchAuctions();
  }, [fetchAuctions]);

  return (
    <div className="common-layout">
      <ProtectedHeader />
      <Loader loading={loading} />
      <section style={{ paddingTop: 20, paddingBottom: 20 }}>
        <HomeAuctionList
          data={allAuctions}
          title={category.title}
          onAuctionClosed={() => null}
        />
      </section>
    </div>
  );
};

export default AllAuctions;
