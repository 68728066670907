import React from "react";
import { Col, Divider, Row } from "antd";
import MachkosherLogo from "../../assets/machkosher-screen-logo.svg";
import "./Footer.css";

import WhatsAppIcon from "../../assets/whatsapp-icon.svg";
import TwitterIcon from "../../assets/twitter-icon.svg";
import InstagramIcon from "../../assets/instagram-icon.png";
import FacebookIcon from "../../assets/facebook-icon.svg";
import { constRoute } from "../../utils/Constants";
import Link from "antd/es/typography/Link";
import { useMediaQuery } from "@uidotdev/usehooks";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const AppFooter = () => {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const companyData = useSelector((state) => state.companyData.companyData);
  const { t } = useTranslation();


  return (
    <footer className="footer">
      <NavLink to={"/"}>
        <img
          src={MachkosherLogo}
          alt={"logo"}
          width={isSmallDevice ? "50%" : "20%"}
        />
      </NavLink>
      <Row gutter={[30]}>
        <Col lg={10} xs={24}>
          <p>{t(`footer.appDescription`)}</p>
        </Col>
        <Col lg={8} xs={24}>
          <Row gutter={[10]}>
            <Col span={12}>
              <Link href={constRoute?.home}>
                <p>{t(`navMenu.home`)}</p>
              </Link>
            </Col>
            <Col span={12}>
              <Link href={constRoute?.aboutUs}>
                <p>{t(`navMenu.aboutUs`)}</p>
              </Link>
            </Col>
            <Col span={12}>
              <Link href={constRoute?.home}>
                <Link href={constRoute?.auctions}>
                  <p>{t(`navMenu.auctions`)}</p>
                </Link>
              </Link>
            </Col>
            <Col span={12}>
              <Link href={constRoute?.contactUs}>
                <p>{t(`navMenu.contact`)}</p>
              </Link>
            </Col>
          </Row>
        </Col>

        <Col lg={4} xs={24}>
          <p>{t(`footer.followUsAt`)}</p>
          <Row className="social-row">
          <Link href={companyData?.facebook?.value}>
              <img src={FacebookIcon} alt={"facebook"} />
            </Link>
            <Link href={companyData?.instagram?.value}>
              <img src={InstagramIcon} alt={"instagram"} />
            </Link>
            <Link href={companyData?.twitter?.value}>
              <img src={TwitterIcon} alt={"twitter"} />
            </Link>
            <Link href={`https://wa.me/${companyData?.whatsApp?.value}`}>
              <img src={WhatsAppIcon} alt={"whatsapp"} />
            </Link>
          </Row>
        </Col>
      </Row>

      <Row className="third-row" align={"middle"} justify={"space-between"}>
        <Col xs={24}>
          <Link href={constRoute?.termsAndConditions} className="medium__font">
            {t(`footer.termsConditions`)}
          </Link>
          <Divider type="vertical" style={{ backgroundColor: "black" }} />
          <Link href={constRoute?.privacyPolicy} className="medium__font">
            {t(`footer.privacyPolicy`)}
          </Link>
        </Col>
      </Row>
      <p style={{ fontSize: "13px", textAlign: "right" }}>
        {t(`footer.rightsReserved`)}
      </p>
    </footer>
  );
};

export default AppFooter;
