import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { Col, Form, message, Row } from "antd";
import LeftArrow from "../../assets/left-arrow.svg";
import useWindowSize from "../../utils/hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import {
  handleError,
  requestErrorHandel,
  setRules,
} from "../../utils/common-functions";
import CustomButton from "../../components/CommonComponents/custom-button/CustomButton";
import CommonTextInput from "../../components/Input/Input";
import CommonDropdown from "../../components/Dropdown/Dropdown";
import { fetchCountriesAPI } from "../../apis/static/StaticAPIs";
import ImageInput from "../../components/ImageInput/ImageInput";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileAPI } from "../../apis/auth/AuthAPIs";
import { _updateProfileAction } from "../../redux/actions/auth/AuthActions";
import { useTranslation } from "react-i18next";
import { validatePhone } from "../../utils/helpers/GeneralHelpers";

const UpdateProfile = () => {
  const user = useSelector((state) => state.auth.user);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [businessLicense, setBusinessLicense] = useState("");
  const [photo, setPhoto] = useState(undefined);
  const [messageApi, contextHolder] = message.useMessage();
  const [phoneNumber, setPhoneNumber] = useState("");

  const onChangeNo = (value) => {
    form.setFieldValue("phoneNo", value);
    setPhoneNumber(value);
    validatePhone(value)
      ? form.setFields([
          {
            name: "phoneNo",
            errors: [""],
          },
        ])
      : form.setFields([
          {
            name: "phoneNo",
            errors: [t(`validations.invalidPhoneNumber`)],
          },
        ]);
  };

  useEffect(() => {
    if (user) {
      form.setFieldsValue({ ...user, countryId: user.country.id });
    }
  }, [user, form]);

  const fetchCountries = async () => {
    try {
      const result = await fetchCountriesAPI();
      if (result.data.statusCode === 200) {
        let countryList = result.data.data;
        countryList = countryList.map((country) => {
          return {
            value: country.id,
            label: country.name,
          };
        });
        setCountries(countryList);
      } else throw result;
    } catch (e) {
      console.log("errror in fetch country", e);
      setCountries([]);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const submit = async (values) => {
    // if (!validatePhone(values.phoneNo)) return;

    setLoading(true);
    const data = {
      ...values,
      countryId: Number(values.countryId),
      photo: photo ?? "",
      license: businessLicense ?? "",
    };

    try {
      const result = await updateProfileAPI(data);
      if (result.data.data && result.data.statusCode === 200) {
        messageApi.success(t(`profileUpdated`));
        dispatch(_updateProfileAction(result.data.data));
        setLoading(false);
      } else throw result;
    } catch (e) {
      setLoading(false);
      // handleError(e, messageApi);
      requestErrorHandel(e, navigate);
    }
  };

  return (
    <div>
      {/* <Loader loading={true} /> */}

      {contextHolder}
      <div className={style.headerContainer}>
        <ProtectedHeader />
      </div>

      <div className={style.updatePasswordContainer}>
        <div className={style.pageContainer}>
          <div className={style.fluidContainer}>
            <Form
              layout="vertical"
              className={style.cancelModalForm}
              form={form}
              onFinish={submit}
            >
              <Row justify={width <= 768 ? "center" : "left"}>
                {width <= 768 && (
                  <img
                    onClick={() => navigate("/")}
                    className={style.arrowIcon}
                    src={LeftArrow}
                    width={20}
                    alt=""
                  />
                )}
                <h1>{t(`navMenu.updateProfile`)}</h1>
              </Row>

              <Row className={style.fluidRow} gutter={40}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: t("validations.requiredFullName"),
                      },
                    ]}
                    name={"name"}
                  >
                    <CommonTextInput
                      required
                      placeholder={t(`signup.fullName`)}
                    />
                  </Form.Item>
                </Col>

                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    rules={setRules(t("validations.requiredEmail"))}
                    name={"email"}
                  >
                    <CommonTextInput
                      disabled
                      required
                      placeholder={t(`signup.email`)}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    name={"phoneNo"}
                    rules={[
                      {
                        required: true,
                        message: t("validations.requiredPhoneNumber"),
                      },
                    ]}
                  >
                    <CommonTextInput
                      type={"phone"}
                      placeholder={t("signup.phoneNumber")}
                      value={phoneNumber}
                      setValue={onChangeNo}
                      required
                      disabled
                      width={"100%"}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    name={"countryId"}
                    rules={[
                      {
                        required: true,
                        message: t("validations.requiredCountry"),
                      },
                    ]}
                  >
                    <CommonDropdown
                      data={countries}
                      placeholder={t(`signup.selectCountry`)}
                      required
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label?.toLowerCase() ?? "").includes(
                          input.toLowerCase()
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    name={"businessAddress"}
                    rules={[
                      {
                        required: true,
                        message: t("validations.requiredBusinessTitle"),
                      },
                    ]}
                  >
                    <CommonTextInput
                      placeholder={t("signup.pleaseEnterBusinessAddress")}
                      required
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item name={"companyName"}>
                    <CommonTextInput
                      placeholder={t("signup.pleaseEnterCompanyName")}
                      // required
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <ImageInput
                    placeholder={t("signup.uploadBusinessLicense")}
                    className={style.file}
                    value={businessLicense}
                    onSelect={setBusinessLicense}
                    setUploading={setLoading}
                    buttonText={t(`signup.upload`)}
                  />
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <ImageInput
                    placeholder={t("signup.uploadBusinessLicense")}
                    className={style.file}
                    value={photo}
                    onSelect={setPhoto}
                    setUploading={setLoading}
                    buttonText={t(`signup.upload`)}
                  />
                </Col>
                <Col className={style.btnContainer} span={24}>
                  <CustomButton
                    htmlType="submit"
                    className={style.btn}
                    title={t("forgotPassword.update")}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateProfile;
