import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { Row } from "antd";
import LeftArrow from "../../assets/left-arrow.svg";
import useWindowSize from "../../utils/hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import { useTranslation } from "react-i18next";
import { getPagesApi } from "../../apis/pages";
import NoDataFound from "../../components/CommonComponents/no-data-found";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [privacyPolicyData, setPrivacyPolicyData] = useState(null);

  const getUserProfileData = async () => {
    try {
      const result = await getPagesApi();
      if (result?.data?.statusCode === 200) {
        setPrivacyPolicyData(
          (localStorage.getItem("i18nextLng") === "ch" &&
            result?.data?.data?.privacyPolicy.valueCh) ||
            (localStorage.getItem("i18nextLng") === "sp" &&
              result?.data?.data?.privacyPolicy.valueSp) ||
            (localStorage.getItem("i18nextLng") === "ar" &&
              result?.data?.data?.privacyPolicy.valueAr) ||
            result?.data?.data?.privacyPolicy.valueEn
        );
      } else throw result;
    } catch (e) {
      console.log("errror in fetch", e);
    }
  };
  useEffect(() => {
    getUserProfileData();
  }, []);

  return (
    <>
      <div className={style.headerContainer}>
        <ProtectedHeader />
      </div>

      <div className={style.pageContainer}>
        <div className={style.fluidContainer}>
          <Row justify={width <= 768 ? "center" : "left"}>
            {width <= 768 && (
              <img
                onClick={() => navigate("/")}
                className={style.arrowIcon}
                src={LeftArrow}
                width={20}
                alt=""
              />
            )}
            <h1>{t("privacyPolicy")}</h1>
          </Row>
          <Row className={style.fluidRow}>
            {(privacyPolicyData && (
              <div
                className={style.leftContainer}
                dangerouslySetInnerHTML={{ __html: privacyPolicyData }}
              ></div>
            )) || <NoDataFound />}

            {/* <div className={style.leftContainer}>
            <p>
                {t("privacyIsImportant")} ( <b>{t("jadeAppos")} </b> <b> {t("companyAppos")} </b>{" "}
                <b> {t("weAppos")} </b> <b> {t("usAppos")} </b> <b> {t("ourAppos")} </b>) {t("weAreCommited")} <b> {t("privacyPolicyAppos")} </b>).
                {t("treatYour")}
              </p>
              <p>
                {t("describesTypes")} ttps://www.machkosher.com {t("theStartBrace")}
                <b>{t("websiteAppos")}</b> {t("andJadeBraces")}<b>{t("appAppos")}</b> {t("togetherWith")} <b>{t("siteAppos")}</b>) {t("ourPracticesCollecting")}
              </p>
              <p>{t("thisPolicyApplies")}</p>
              <ul>
                <li>
                  <p className={style.listPara}>{t("onTheSite")}</p>
                </li>
                <li>
                  <p className={style.listPara}>
                    {t("inEmailText")}
                  </p>
                </li>
                <li>
                  <p className={style.listPara}>
                   {t("whenYouInteract")}
                  </p>
                </li>
                <li>
                  <p className={style.listPara}>
                    {t("byAnyThird")}
                  </p>
                </li>
              </ul>
              <h4>{t("purposeOfThis")}</h4>
              <p>
                {t("policyAims")}
              </p>
              <p>
                {t("policyTogether")}
              </p>
              <h4>{t("controller")}</h4>
              <p>
                {t("m3CarsMore")}
              </p>
              <p>
               {t("haveAppointed")}
              </p>
              <a href={`mailto:${companyData?.email?.value}`}> { renderItemDataOrEmptyNull(companyData?.email?.value) } </a>
              <h4>{t("collectionInformation")}</h4>
              <p>
                {t("infoAboutIndividual")}
              </p>
              <p>
                {t("weMayCollect")}
              </p>
              <ul>
                <li>
                  <p className={style.listPara}>
                    <b>{t("identityData")}</b> {t("includesFirstName")}
                  </p>
                </li>
                <li>
                  <p className={style.listPara}>
                    <b>{t("contactData")}</b> {t("includesCompanyName")}
                  </p>
                </li>
                <li>
                  <p className={style.listPara}>
                    <b>{t("transactionData")}</b>{t("includesDetailsAbout")}
                  </p>
                </li>
                <li>
                  <p className={style.listPara}>
                    <b>{t("technicalData")}</b> {t("includesInternetProtocol")}
                  </p>
                </li>
                <li>
                  <p className={style.listPara}> 
                    <b>{t("profileData")}</b> {t("includesYourCompany")}
                  </p>
                </li>
                <li>
                  <p className={style.listPara}>
                    <b>{t("usageData")}</b> {t("includesSite")}
                  </p>
                </li>
                <li>
                  <p className={style.listPara}>
                    <b>{t("marketingCommunications")}</b> {t("includesYourPreferences")}
                  </p>
                </li>
              </ul>
              <p>
               {t("weCollect")} <b>{t("aggregatedData")}</b> {t("suchAsStatistical")}
              </p>
              <p>
                {t("weNotCollect")}{" "}
                <b> {t("specialCategories")} </b>
                {t("aboutYou")}
              </p>
              <p>
               {t("highlyRecommended")}
              </p>
              <h4>{t("useOfInfo")}</h4>
              <p>
               {t("weWillOnly")}
              </p>
              <p>
                {t("whereWeNeed")}
              </p>
              <p>
                {t("necessaryForLegitimate")}
              </p>
              <p>{t("thanOneBasis")}</p>
              <h4>{t("thirdPartyMarketing")}</h4>
              <p>
                {t("companyDoestNot")}
              </p>
              <h4>{t("disclosuresOfYour")}</h4>
              <p>
                {t("weMayFind")}{" "}
                <b>{t("internalThirdParties")}</b> ({t("setOutInGlossary")})
                {t("or")} <b>{t("externalThirdParties")}</b> ({t("setOutInGlossary")}).
              </p>
              <p>
                {t("weMayDeem")}
              </p>
              <p>
                {t("weMayDisclose")}                
              </p>
              <ul>
                <li>
                  <p className={style.listPara}>
                    {t("remainInConformance")}
                  </p>
                </li>
                <li>
                  <p className={style.listPara}>
                    {t("performUnderDemanding")}
                    <a href="ttps://www.machkosher.com">
                      ttps://www.machkosher.com
                    </a>{" "}
                    {t("generalPublic")}
                  </p>
                </li>
              </ul>
              <p>
                {t("weMayAlso")}
              </p>
              <p>
               {t("weRequireAll")}
              </p>
              <h4>{t("hangeOfPurpose")}</h4>
              <p>
                {t("weWilOnly")}
              </p>
              <p>
                {t("ifWeNeed")}
              </p>
              <p>
                {t("pleaseNoteThat")}
              </p>
              <h4>{t("ifYouFail")}</h4>
              <p>
               {t("whereWeNeedByLow")}
              </p>
              <h4>{t("Under18")}</h4>
              <p>
                {t("siteNotChild")}
                <b>
                  {t("infoOfChildren")}
                </b>{" "}
                {t("withoutVerifiable")}
              </p>
              <h4>{t("linkWebs")}</h4>
              <p>
                <b>
                  {t("ourSiteMay")}
                </b>
              </p>
              <h4>{t("dataSecurity")}</h4>
              <p>
                {t("companyAppropriate")}
              </p>
              <p>
                {t("weSecureSocket")}
              </p>
              <p>
                {t("weProcedures")}
              </p>
              <p>
                {t("safetySecurityInfo")}
              </p>
              <p>
               {t("unfortunatelyTransmission")}
              </p>
              <h4>{t("yourLegalRights")}</h4>
              <p>
                {t("underCertainCircumstances")}
              </p>
              <ul>
                <li>
                  <p className={style.listPara}>
                    {t("requestAccessInfo")}
                  </p>
                </li>
                <li>
                  <p className={style.listPara}>
                    {t("requestCorrection")}
                  </p>
                </li>
                <li>
                  <p className={style.listPara}>
                    {t("requestErasure")}
                  </p>
                </li>
                <li>
                  <p className={style.listPara}>
                    {t("objectToProcessing")}
                  </p>
                </li>
                <li>
                  <p className={style.listPara}>
                    {t("requestRestriction")}
                  </p>
                </li>
                <li>
                  <p className={style.listPara}>
                    {t("requestTransfer")}
                  </p>
                </li>
                <li>
                  <p className={style.listPara}>{t("rightWithdraw")}</p>
                </li>
              </ul>
              <p>
               {t("ifYouWish")}”
              </p>
              <h4>{t("needFromYou")}</h4>
              <p>
                {t("needSpecificInfo")}
              </p>
              <h4>{t("timeLimit")}</h4>
              <p>
                {t("weTryRespond")}
              </p>
              <h4>{t("chagesPrivacy")}</h4>
              <p>
                {t("reserveRight")}<a href="https://www.MachKosher.com" target="_blank" rel="noopener noreferrer">ttps://www.machkosher.com,</a> {t("soThatOur")}
              </p>
              <h4>{t("accessingCorrecting")}</h4>
              <p>
               {t("youCanReview")}
              </p>
              <h4>{t("acceptanceTerms")}</h4>
              <p>
               {t("throughUse")}
              </p>
              <h4>{t("howContact")}</h4>
              <p>
                {t("anyQuestion")}
              </p>
              <p>
                <b>
                  {t("emailAddress")}:{" "}
                  <a href="mailto:info@ade.com">info@machkosher.com</a>
                </b>
              </p>
            </div> */}
          </Row>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
