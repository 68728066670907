import { GET_COMPANY_DATA } from "../../actions/company";

const initialState = {
  companyData: {},
};

const CompanyData = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_DATA:
      return {
        ...state,
        companyData: action.payload,
      };
    default:
      return state;
  }
};

export default CompanyData;
