import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { Col, Row, Slider } from "antd";
import LeftArrow from "../../assets/left-arrow.svg";
import useWindowSize from "../../utils/hooks/useWindowSize";
import { useLocation, useNavigate } from "react-router-dom";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import InfoIcon from "../../assets/info-circle.svg";
import CommonTextInput from "../../components/Input/Input";
import BankImage from "../../assets/bank.svg";
import { constRoute } from "../../utils/Constants";
import { getWalletApi, postdepositApi } from "../../apis/deposit";
import {
  copyToClipboard,
  dallasTimezone,
  errorMessage,
  renderItemDataOrEmptyNull,
  requestErrorHandel,
  successMessage,
} from "../../utils/common-functions";
import Loader from "../../components/Loader/Loader";
import { CopyOutlined } from "@ant-design/icons";
import CustomButton from "../../components/CommonComponents/custom-button/CustomButton";
import moment from "moment-timezone";

import { useTranslation } from "react-i18next";
import { getCryptoListAPI } from "../../apis/crypto";
import NoDataFound from "../../components/CommonComponents/no-data-found";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

const Deposit = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { width } = useWindowSize();
  const locationdata = useLocation();
  const location = JSON.parse(locationdata.state);
  const date = location?.date;
  const file = location?.file;
  const filesDepositAmount = location?.depositAmount;
  const [depositAmount, setDepositAmount] = useState(0);
  const [walletData, setWalletData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cryptoData, setCryptoData] = useState(null);

  dayjs.extend(utc);
  dayjs.extend(timezone);
  const tz = "America/Chicago";

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getWalletApi();
      if (response?.data?.statusCode === 200) {
        setWalletData(response?.data?.data);
      }
    } catch (error) {
      requestErrorHandel(error, navigate);
    } finally {
      setLoading(false);
    }
  };

  const fetchCryptoListData = async () => {
    setLoading(true);
    try {
      const response = await getCryptoListAPI();
      if (response?.data?.statusCode === 200) {
        setCryptoData(
          response?.data?.data?.find((item) => item?.isEnable === true)
        );
      }
    } catch (error) {
      requestErrorHandel(error, navigate);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchCryptoListData();
  }, []);

  useEffect(() => {
    if (filesDepositAmount) {
      setDepositAmount(filesDepositAmount);
    }
  }, [filesDepositAmount]);

  const onDeposit = async () => {
    setLoading(true);
    try {
      const payload = {
        amount: depositAmount / 5,
        date: date,
        // moment.tz(date, dallasTimezone) ||
        // moment.tz(new Date(), dallasTimezone),
        receipt: file,
      };
      const response = await postdepositApi(payload);
      if (response?.data?.statusCode === 200) {
        successMessage(t(`depositSuccess`));
        navigate(constRoute?.auctions);
      }
    } catch (error) {
      requestErrorHandel(error, navigate);
    } finally {
      setLoading(false);
    }
  };

  const onChangeLimit = (e) => {
    setDepositAmount(5000 * e);
  };

  const renderLimitsBasedOnLanguage = () => {
    if (i18n.language === "ar") {
      return (
        <Row justify={"space-between"}>
          <p>{renderItemDataOrEmptyNull(500000, 2)}</p>
          <p>{renderItemDataOrEmptyNull(depositAmount, 2)}</p>
        </Row>
      );
    } else {
      // Assuming 'else' as English or default language
      return (
        <Row justify={"space-between"}>
          <p>{renderItemDataOrEmptyNull(depositAmount, 2)}</p>
          <p>{renderItemDataOrEmptyNull(500000, 2)}</p>
        </Row>
      );
    }
  };

  return (
    <>
      <div className={style.headerContainer}>
        <ProtectedHeader />
      </div>
      <Loader loading={loading} />
      <div className={style.pageContainer}>
        <div className={style.fluidContainer}>
          <Row justify={width <= 768 ? "center" : "left"}>
            {width <= 768 && (
              <img
                onClick={() => navigate("/")}
                className={style.arrowIcon}
                src={LeftArrow}
                width={20}
                alt=""
              />
            )}
            <h1>{t(`deposit.deposit`)}</h1>
          </Row>
          <Row gutter={20} className={style.topRow}>
            <Col md={12} sm={24} xs={24}>
              <div className={style.leftColContainer}>
                <h3>{t(`deposit.chooseYourLimit`)}</h3>
                <Slider
                  defaultValue={1}
                  value={depositAmount / 5000}
                  onChange={onChangeLimit}
                />

                {/* <Row justify={"space-between"}>
                  <p>${renderItemDataOrEmptyNull(depositAmount, 2)}</p>
                  <p>${renderItemDataOrEmptyNull(500000, 2)}</p>
                </Row> */}
                {renderLimitsBasedOnLanguage()}
                <Row justify={"center"}>
                  <span>{t(`or`)}</span>
                </Row>
                <CommonTextInput
                  type={"number"}
                  placeholder="Enter your limit here"
                  value={depositAmount}
                  onChange={(e) => {
                    if (e.target.value <= 500000) {
                      setDepositAmount(Number(e.target.value));
                    }
                  }}
                />
                <Row justify={"space-between"} className={style.totalPay}>
                  <h4>{t(`deposit.youShouldPay`)}</h4>
                  <h4>${renderItemDataOrEmptyNull(depositAmount / 5, 2)}</h4>
                </Row>
              </div>
              <br />
              <br />
              <Row
                onClick={() => {
                  if (depositAmount) {
                    navigate(constRoute.uploadReceipt, {
                      state: { depositAmount },
                    });
                  } else {
                    errorMessage(t(`depositAmountError`));
                  }
                }}
                className={style.rightInfoRow}
              >
                <Row>
                  {file ? (
                    <img src={file} alt="" />
                  ) : (
                    <img src={BankImage} style={{ width: "50px" }} alt="bank" />
                  )}
                  <Row align={"middle"}>
                    <div>
                      <h4> {t(`deposit.uploadReceipt`)} </h4>
                      {date && (
                        <CommonTextInput
                          showTime
                          disabled
                          type="text"
                          // value={moment.tz(date, dallasTimezone)}
                          value={dayjs(date)
                            .tz(tz)
                            .format("DD/MM/YYYY  hh:mm A")}
                          placeholder="Date"
                        />
                      )}
                    </div>
                  </Row>
                </Row>
                <p className={style.uploadText}>
                  {" "}
                  {file || date ? t(`reUpload`) : t(`deposit.upload`)}{" "}
                </p>
              </Row>
              <Row>
                <CustomButton
                  disabled={!depositAmount || !file}
                  onClick={onDeposit}
                  className={style.btn}
                  title={t(`deposit.deposit`)}
                />
              </Row>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <div className={style.rightTopColContainer}>
                <Row justify={"space-between"}>
                  <p>{t(`deposit.currentDeposit`)}</p>
                  <p>
                    $
                    {renderItemDataOrEmptyNull(
                      Number(walletData?.currentBalance),
                      2
                    )}
                  </p>
                </Row>
                <Row justify={"space-between"}>
                  <p>{t(`deposit.totalLimit`)}</p>
                  <p>
                    ${renderItemDataOrEmptyNull(walletData?.totalBalance, 2)}
                  </p>
                </Row>
                <Row justify={"space-between"}>
                  <p>{t(`deposit.availableLimit`)}</p>
                  <p>
                    $
                    {renderItemDataOrEmptyNull(walletData?.availableBalance, 2)}
                  </p>
                </Row>
              </div>

              <div className={style.rightColContainer}>
                <Row className={style.rightMainRow}>
                  <Col span={24}>
                    <Row className={style.rightInfoRowInfoText}>
                      <img src={InfoIcon} alt="" />
                      <p>{t(`deposit.makeDepositCrypto`)}</p>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <div className={style.rightColPayment}>
                      <h3>{t(`deposit.paymentViaCrypto`)}</h3>
                      {(cryptoData && (
                        <>
                          <div className={style.QRCodeImage}>
                            <img src={cryptoData?.image} alt="qrCode" />
                          </div>

                          <Row className={style.rightInfoRow}>
                            <div className={style.walletContainer}>
                              <h4>{t(`deposit.walletDetails`)}</h4>
                              <p> {cryptoData?.name} USDT Tron (TRC20) ID</p>
                              <h4 className={style.addressID}>
                                {renderItemDataOrEmptyNull(cryptoData?.value)}
                                <CopyOutlined
                                  className={style.copyIcon}
                                  onClick={() =>
                                    copyToClipboard(cryptoData?.value)
                                  }
                                />
                              </h4>
                              <p>{t(`deposit.uploadReceiptScreenshot`)}</p>
                            </div>
                          </Row>
                        </>
                      )) || <NoDataFound />}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Deposit;
