import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip as ReactTooltip } from "react-tooltip";
import InfoIcon from "../../assets/info.svg";
export default function Index() {
  const { t } = useTranslation();
  const tooltipContent = `
    <div>
      <h3 style="font-size: 14px;margin-bottom:0px;color:red;font-weight:bold">
        ${t("auctionDetails.proxyBid")}
      </h3>
      <p style="font-size: 12px;color:initial">
        ${t("auctionDetails.bidInfoBody")}
      </p>
    </div>
  `;
  return (
    <>
      <img
        data-tooltip-id="bidInfo"
        style={{ width: 15, height: 15, marginLeft: 10 }}
        src={InfoIcon}
        alt="Information icon"
      />
      <ReactTooltip
        id="bidInfo"
        place="top"
        openOnClick={true}
        html={tooltipContent}
        className="custom-tooltip" // Add the custom class
      />
    </>
  );
}
