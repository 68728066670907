export const getAuctionTimeRemaining = (endsAt) => {
  const currentTime = Date.now();
  const endsAtTime = new Date(endsAt).getTime();
  const timeDifference = Math.max(0, endsAtTime - currentTime) / 1000; // Convert to seconds

  const days = Math.floor(timeDifference / (3600 * 24));
  const hours = Math.floor((timeDifference % (3600 * 24)) / 3600);
  const minutes = Math.floor((timeDifference % 3600) / 60);
  const seconds = Math.floor(timeDifference % 60);

  let remainingTime = "";

  if (days > 0) {
    remainingTime = `${days}d: `;
  }

  remainingTime += `${hours}h : ${minutes}m : ${seconds}s`;

  return remainingTime;
};

export const validatePhone = (phone) => {
  const regex = /^[0-9]{10,15}$/;
  // const regex =
  // /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{5}[-\s\.]?[0-9]{2,6}$/;
  return regex.test(phone);
};
