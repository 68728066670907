import { Card, Col, Empty, message, Row } from "antd";
import { useNavigate } from "react-router-dom";
import {
  addToWatchListAPI,
  fetchWatchlistAPI,
} from "../../apis/watchlist/WatchlistAPI";
import { useDispatch, useSelector } from "react-redux";
import {
  _addToWatchListAction,
  _removeFromWatchListAction,
  _setWatchlistAction,
} from "../../redux/actions/watchlist/WatchListActions";
import { constRoute, CREDENTIALS } from "../../utils/Constants";
import EyeIcon from "../../assets/open-eye-icon.svg";
import RemoveWatchListIcon from "../../assets/remove-watchlist-icon.svg";
import "./HomeAuction.css";
import {
  handleError,
  logoutAndNavigateToHome,
  renderAuctionItemBGColorOnStatus,
  renderItemDataOrEmptyNull,
  requestErrorHandel,
} from "../../utils/common-functions";
import { useTranslation } from "react-i18next";
import AppButton from "../Button/Button";
import HighestBidderIcon from "../../assets/highest-bidder-icon.svg";
import ClockIcon from "../../assets/clock-icon.svg";
import TimeRemainingTimer from "../CommonComponents/RemainingTimeTimer";
import { _setAuctionsAction } from "../../redux/actions/home/HomeActions";
import { useCallback, useEffect } from "react";

const HomeAuctionList = ({
  data,
  title,
  onAuctionClosed,
  loading,
  ...props
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const watchlist = useSelector((state) => state.watchlist.data);
  const auctions = useSelector((state) => state.home.auctions);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const openDetails = (data, coverImage) => () => {
    navigate(`/auction-detail/${data.id}`, {
      state: { ...data, coverImage },
    });
  };

  useEffect(() => {
    fetchWatchlist();
  }, []);

  const renderBidDetails = (el, index) => {
    return (
      <Row style={{ margin: "15px 28px 28px 28px" }} id={index}>
        <Col md={24} sm={24} xs={24}>
          <div className="auction-head-container">
            <div>
              <h1>{renderItemDataOrEmptyNull(el?.name)}</h1>
              <p className="t-slate">
                {t(`auctionDetails.auctionID`)}: {el?.identifier}
              </p>
            </div>
            {isLoggedIn && (
              <>
                <div className="bidPriceCol">
                  {document.location.pathname !== "/" && (
                    <div
                      className="favIconContainer"
                      style={{
                        background: renderAuctionItemBGColorOnStatus(
                          el?.bidStatuses?.identifier
                        ),
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        addToWatchList(el.id);
                      }}
                    >
                      <img
                        src={
                          isAddedToWatchList(el?.id)
                            ? RemoveWatchListIcon
                            : EyeIcon
                        }
                        alt={"view"}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>

          <Row className="firstRowContainer">
            <Col md={8} sm={12} xs={12}>
              <Row gutter={20} className={"auctionPropertiesRow"}>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="medium__font t-slate">
                    {t(`auctionDetails.serialNumber`)}
                  </span>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="semiBold__font">
                    {renderItemDataOrEmptyNull(el.serialNumber)}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col md={8} sm={12} xs={12}>
              <Row className={"auctionPropertiesRow"}>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="medium__font t-slate">
                    {t(`manufacturer`)}
                  </span>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="semiBold__font">
                    {renderItemDataOrEmptyNull(el.manufacture?.name)}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col md={8} sm={12} xs={12}>
              <Row className={"auctionPropertiesRow"}>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="medium__font t-slate">
                    {t(`auctionDetails.Sku`)}
                  </span>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span
                    className="semiBold__font"
                    style={{ wordBreak: "break-word" }}
                  >
                    {renderItemDataOrEmptyNull(el.sku)}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col md={8} sm={12} xs={12}>
              <Row className={"auctionPropertiesRow"}>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="medium__font t-slate">
                    {t(`auctionDetails.grade`)}
                  </span>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="semiBold__font">
                    {renderItemDataOrEmptyNull(el.grade)}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col md={8} sm={12} xs={12}>
              <Row className={"auctionPropertiesRow"}>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="medium__font t-slate">{t(`quantity`)}</span>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="semiBold__font">
                    {renderItemDataOrEmptyNull(el.totalUnits)}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col md={8} sm={12} xs={12}>
              <Row gutter={20} className={"auctionPropertiesRow"}>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="medium__font t-slate">
                    {t(`auctionDetails.noOfBids`)}
                  </span>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="semiBold__font">
                    {renderItemDataOrEmptyNull(el.totalBids)}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col md={8} sm={12} xs={12}>
              <Row className={"auctionPropertiesRow"}>
                {el?.totalBids > 0 ? (
                  <>
                    <Col lg={12} md={24} sm={24} xs={24}>
                      <span className="medium__font t-slate">
                        {t(`auctionDetails.lastBid`)}
                      </span>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                      <span className="semiBold__font">
                        $
                        {renderItemDataOrEmptyNull(
                          el?.lastBidPrice / el.totalUnits,
                          2
                        )}
                        /Per Unit
                      </span>
                    </Col>
                  </>
                ) : (
                  <>
                    {el.price > 0 && (
                      <>
                        <Col lg={12} md={24} sm={24} xs={24}>
                          <span className="medium__font t-slate">
                            {t(`auctionDetails.unitPrice`)}
                          </span>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                          <span className="semiBold__font">
                            $
                            {renderItemDataOrEmptyNull(
                              el.price / el.totalUnits,
                              2
                            )}
                            /Per Unit
                          </span>
                        </Col>
                      </>
                    )}
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const addToWatchList = async (auctionId) => {
    try {
      const isWatchlist = isAddedToWatchList(auctionId);
      const authToken = localStorage.getItem(CREDENTIALS.AUTH_TOKEN);
      if (authToken?.length < 1 || !authToken) {
        messageApi.error(t("watchlist.loginToAddAuctionMessage"));
        logoutAndNavigateToHome();
        return;
      }
      // setLoading(true);
      const result = await addToWatchListAPI(auctionId);
      // setLoading(false);
      if (result.data.statusCode === 200) {
        // add the auction to watchlist reducer state
        if (isWatchlist) {
          dispatch(_removeFromWatchListAction(result.data.data));
          messageApi.success(t(`auctionRemovedWatchlist`));
        } else {
          dispatch(_addToWatchListAction(result.data.data));
          messageApi.success(t(`auctionAddedWatchlist`));
        }
      }
    } catch (e) {
      // setLoading(false);
      // handleError(e);
      requestErrorHandel(e, navigate);
    }
  };

  const fetchWatchlist = async () => {
    try {
      const authToken = localStorage.getItem(CREDENTIALS.AUTH_TOKEN);
      if (authToken?.length < 1 || !authToken) {
        return;
      }
      const result = await fetchWatchlistAPI();
      if (result.data?.statusCode === 200 && result.data.data) {
        dispatch(_setWatchlistAction(result.data.data));
      }
    } catch (e) {
      console.log("error in fetch watchlist...", e);
    }
  };

  const isAddedToWatchList = (auctionId) => {
    const watchlistIds = watchlist.map((el) => el.id);
    return watchlistIds?.includes(auctionId);
  };

  const viewAllAuctions = () => {
    navigate(constRoute.auctions);
  };

  return (
    <>
      {props.type !== "auctions-page" && (
        <Row
          align={"middle"}
          justify="space-between"
          className="auction-heading"
        >
          <h2 className="bold__font">{title ?? t("home.featuredAuctions")}</h2>
          {!title && (
            <p
              className="t-red t-underline medium__font t-cursor"
              onClick={viewAllAuctions}
            >
              {data?.length > 0 && t("home.viewAll")}
            </p>
          )}
        </Row>
      )}
      {contextHolder}
      <div className="auction-main">
        {data && data.length > 0 ? (
          data?.map((el, index) => {
            let coverImage = el.files?.find(
              (file) => file?.fileType === "image"
            );
            coverImage = coverImage?.file;
            return (
              <Card
                hoverable
                bordered
                onClick={openDetails(el, coverImage)}
                className="auction-item auction-item-card"
                style={{
                  background: renderAuctionItemBGColorOnStatus(
                    el?.bidStatuses?.identifier
                  ),
                }}
                key={index}
              >
                {/* <p className="bold__font">{el.identifier}</p> */}
                {el?.bidStatuses?.barStatus ? (
                  <div
                    className="highestBidder"
                    style={{
                      background: renderAuctionItemBGColorOnStatus(
                        el?.bidStatuses?.identifier
                      ),
                    }}
                  >
                    <img src={HighestBidderIcon} alt="Highest" />
                    <p> {el?.bidStatuses?.barStatus}</p>
                  </div>
                ) : null}

                <Row
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <Col md={24} sm={24} xs={24}>
                    {renderBidDetails(el, index)}
                  </Col>
                </Row>

                <Row justify={"space-between"} align={"middle"}>
                  <Col sm={12} xs={12}>
                    <Row className="item">
                      <img
                        src={ClockIcon}
                        alt={"time"}
                        className={"auction-clock-icon"}
                      />
                      <p className="semiBold__font">
                        <TimeRemainingTimer
                          endAt={el.endAt}
                          onAuctionClosed={() => onAuctionClosed(el.id)}
                        />
                      </p>
                    </Row>
                  </Col>

                  <Col className="bidNowBtnCol" sm={12} xs={12}>
                    <AppButton className={"bidNowButton"}>
                      {t(`auctionDetails.bidNow`)}
                    </AppButton>
                  </Col>
                </Row>
              </Card>
            );
          })
        ) : (
          <>
            {loading ? (
              <div style={{ height: "200px" }}></div>
            ) : (
              <Empty
                description={<p className="medium__font">{t("noDataFound")}</p>}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default HomeAuctionList;
