import { Col, Divider, Drawer, Row } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  _setLoginAction,
  _updateProfileAction,
} from "../../redux/actions/auth/AuthActions";
import i18n from "../../translation/i18nConfig";
import {
  capitalizeString,
  requestErrorHandel,
  successMessage,
} from "../../utils/common-functions";
import { constRoute, CREDENTIALS, LANGUAGE } from "../../utils/Constants";
import Button from "../Button/Button";
import "./MobileDrawer.css";
import { getUserProfile } from "../../apis/auth/AuthAPIs";
import { _setWatchlistAction } from "../../redux/actions/watchlist/WatchListActions";

import refreshIcon from "../../assets/refresh.svg";

const MobileDrawer = ({ open, toggleDrawer }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.user);
  const [isLoading, setisLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getUserProfileData = async () => {
    try {
      setisLoading(true);

      const result = await getUserProfile();
      if (result?.data?.statusCode === 200) {
        dispatch(_updateProfileAction(result?.data?.data));
        setisLoading(false);
      } else throw result;
    } catch (e) {
      requestErrorHandel(e, navigate);
      setisLoading(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem(CREDENTIALS.AUTH_TOKEN)?.length > 1) {
      getUserProfileData();
    }
  }, []);

  const onLogout = () => {
    navigate(constRoute?.home);
    localStorage.removeItem(CREDENTIALS.AUTH_TOKEN);
    dispatch(_setLoginAction(false, undefined));
    dispatch(_setWatchlistAction([]));
    successMessage(t(`logoutSuccess`));
  };

  const changeLanguage = (code) => () => {
    localStorage.setItem(LANGUAGE, code);
    i18n.changeLanguage(code);
  };

  const languageMenu = () => {
    return (
      <Fragment>
        {" "}
        <Divider orientation="left" orientationMargin="0">
          <p className="t-slate t-drawer-header">{t(`navMenu.language`)}</p>
        </Divider>
        <div className="drawer-navlink-list">
          <NavLink onClick={changeLanguage("en")}>
            {t(`navMenu.english`)}
          </NavLink>
          <NavLink onClick={changeLanguage("ar")}>
            {t(`navMenu.arabic`)}
          </NavLink>
          <NavLink onClick={changeLanguage("sp")}>
            {t(`navMenu.russian`)}
          </NavLink>
          <NavLink onClick={changeLanguage("ch")}>
            {t(`navMenu.chinese`)}
          </NavLink>
        </div>
      </Fragment>
    );
  };

  return (
    <Drawer
      title=""
      placement={"left"}
      onClose={toggleDrawer(false)}
      open={open}
      key={"drawer"}
      className={"m-drawer"}
    >
      <>
        {isLoggedIn && !(user?.accountStatus === "approved") && (
          <Row
            justify={"space-between"}
            className="account-status-header"
            align={"middle"}
          >
            <p className="bold__font">{t(`navMenu.accountStatus`)}</p>
            <p className="medium__font">
              {capitalizeString(user?.accountStatus)}
            </p>

            {user?.accountStatus !== "blocked" && (
              <>
                {isLoading ? (
                  "..."
                ) : (
                  <img
                    onClick={getUserProfileData}
                    src={refreshIcon}
                    style={{
                      height: 15,
                      padding: "0 10px",
                      cursor: "pointer",
                    }}
                  />
                )}
              </>
            )}
          </Row>
        )}
        {isLoggedIn && user?.accountStatus === "approved" ? (
          <Fragment>
            <Divider orientation="left" orientationMargin="0">
              <p className="t-slate t-drawer-header">
                {t(`navMenu.generalMenu`)}
              </p>
            </Divider>
            <div className="drawer-navlink-list">
              <NavLink to={constRoute.home}>{t(`navMenu.home`)}</NavLink>
              <NavLink to={constRoute.aboutUs}>{t(`navMenu.aboutUs`)}</NavLink>
              <NavLink to={constRoute.auctions}>
                {t(`navMenu.auctions`)}
              </NavLink>
              <NavLink to={constRoute.contactUs}>
                {t(`navMenu.contact`)}
              </NavLink>
            </div>
            <Divider orientation="left" orientationMargin="0">
              <p className="t-slate t-drawer-header">{t(`navMenu.account`)}</p>
            </Divider>
            <div className="drawer-navlink-list">
              <NavLink to={constRoute.notification}>
                {t(`navMenu.notifications`)}
              </NavLink>
              <NavLink to={"/my-bids"}>{t(`navMenu.myBids`)}</NavLink>
              <NavLink to={constRoute.watchlist}>
                {t(`navMenu.watchlist`)}
              </NavLink>
              <NavLink to={constRoute.deposit}>{t(`navMenu.deposit`)}</NavLink>
              <NavLink to={constRoute.updateProfile}>
                {t(`navMenu.updateProfile`)}
              </NavLink>
              <NavLink to={constRoute.updatePassword}>
                {t(`navMenu.updatePassword`)}
              </NavLink>
            </div>
            {languageMenu()}
            <Divider></Divider>
            <div className="drawer-navlink-list">
              <NavLink to={constRoute?.home} onClick={onLogout}>
                {t(`navMenu.logout`)}
              </NavLink>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <Divider orientation="left" orientationMargin="0">
              <p className="t-slate t-drawer-header">General Menu</p>
            </Divider>
            <div className="drawer-navlink-list">
              <NavLink to={constRoute.home}>{t(`navMenu.home`)}</NavLink>
              <NavLink to={constRoute.aboutUs}>{t(`navMenu.aboutUs`)}</NavLink>
              <NavLink to={constRoute.auctions}>
                {t(`navMenu.auctions`)}
              </NavLink>
              <NavLink to={constRoute.contactUs}>
                {t(`navMenu.contact`)}
              </NavLink>
            </div>
            {languageMenu()}
            {!isLoggedIn && (
              <footer className="drawer-footer">
                <p className="t-center t-slate">
                  {/* To get full access sign in or sign up */}
                  {t("fullAccessPrompt")}
                </p>
                <Row justify={"center"} gutter={[20]}>
                  <Col>
                    <Button onClick={() => navigate("/login")}>
                      {t(`login.sign-in`)}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => navigate("/signup")}
                      type={"default"}
                    >
                      {t(`signup.signup`)}
                    </Button>
                  </Col>
                </Row>
              </footer>
            )}
          </Fragment>
        )}{" "}
      </>
    </Drawer>
  );
};

export default MobileDrawer;
