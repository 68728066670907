import React from "react";
import { useTranslation } from "react-i18next";
import DisclamerIcon from "../../assets/disclamer.png";

export default function Index() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return (
    <>
      <p>
        <p
          style={{
            fontSize: 14,
            fontWeight: "500",
            color: "#E51837",
            marginBottom: 4,
          }}
        >
          <img
            src={DisclamerIcon}
            style={{ width: 12, height: 12, marginRight: 8, color: "#E51837" }}
            alt="Disclamer icon"
          />
          <span style={{ marginRight: currentLanguage == "ar" ? "10px" : "" }}>
            {t(`auctionDetails.disclaimerTitle`)}
          </span>
        </p>
        <span
          className="disclamer"
          style={{
            fontSize: 12,
          }}
        >
          {t(`auctionDetails.disclaimerBody`)}
        </span>
      </p>
    </>
  );
}
