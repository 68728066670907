import client from "../config";

export const fetchWatchlistAPI = async (
  grade = "",
  ending = "",
  manufacturer = "",
  model = "",
  type = ""
) => {
  return await client.get(
    `/auctions/watch/list?page=1&gradeId=${grade}&type=${type}&isSoon=${ending}&manufactureId=${manufacturer}&modelId=${model}&limit=1000`
  );
};

export const addToWatchListAPI = async (auctionId) => {
  return await client.post(`/auctions/watch/list/${auctionId}`);
};
